import { InfiniteUserRolesAdminPersonaApiFactory } from '@eloomi/eloomi-users-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { DataRequestOptions } from '@/common/components/table';
import { buildFilter } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const userRolesAdminPersonaApiFactory = authorizeClient(InfiniteUserRolesAdminPersonaApiFactory);

export async function getAllRolesWithUserCount(options: DataRequestOptions = { page: 1, pageSize: 10 }) {
    const filters = buildFilter('name', options.searchQuery);
    return userRolesAdminPersonaApiFactory
        .getAllInfiniteRoleWithUserCount(filters, undefined, options.page, options.pageSize)
        .then(extractAxiosData);
}

export async function getRoleDetailsById(id: number) {
    return userRolesAdminPersonaApiFactory.getInfiniteUserRole(id).then(extractAxiosData);
}

export async function getAllPermissions() {
    return userRolesAdminPersonaApiFactory.getAllPermissions().then(extractAxiosData);
}

export async function getUsersByRole(id: number, options: DataRequestOptions) {
    return userRolesAdminPersonaApiFactory
        .getUsersByRole(id, undefined, undefined, options.page, options.pageSize)
        .then(extractAxiosData);
}
