import { differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';

import useTranslation from '@/common/composables/use-translation';
import { DateInAnyForm, formatDateString, getDate } from '@/common/services';

export function useDates() {
    const { currentLanguage, hourFormat } = useTranslation();

    // get date in "Tuesday, Jan 24, 2022" format
    const getLocalizedDate = (
        date?: Date | null,
        options?: {
            separator?: string;
            withDayOfWeek?: boolean;
            withMonthBeforeDay?: boolean;
            withYear?: boolean;
        }
    ) => {
        if (!date) {
            return '';
        }
        const withDayOfWeek = options?.withDayOfWeek ?? true;
        const withMonthBeforeDay = options?.withMonthBeforeDay ?? true;
        const withYear = options?.withYear ?? true;
        const separator = options?.separator ?? ', ';

        const day = date.getDate().toString();
        const month = date.toLocaleDateString(currentLanguage.value, { month: 'short' });
        const parts: string[] = [];

        if (withDayOfWeek) {
            parts.push(date.toLocaleDateString(currentLanguage.value, { weekday: 'long' }));
        }
        if (withMonthBeforeDay) {
            parts.push(`${month} ${day}`);
        } else {
            parts.push(`${day} ${month}`);
        }
        if (withYear) {
            parts.push(date.getFullYear().toString());
        }

        return parts.join(separator);
    };

    // get time in "15:30" / "3:30pm" format
    const getLocalizedTime = (date?: Date | null) => {
        if (!date) {
            return '';
        }

        return date.toLocaleTimeString(currentLanguage.value, {
            hour: 'numeric',
            minute: '2-digit',
            hourCycle: hourFormat.value,
        });
    };

    //get time in "Tuesday, Jan 24, 2022 | 15:30 OR 3:30pm" format
    const getLocalizedDateTime = (
        date?: DateInAnyForm,
        options?: {
            separator?: string;
            withDayOfWeek?: boolean;
            withMonthBeforeDay?: boolean;
            withYear?: boolean;
        }
    ) => {
        const nativeDate = getDate(date);
        if (!nativeDate) {
            return '';
        }

        const withDayOfWeek = options?.withDayOfWeek ?? true;
        const withMonthBeforeDay = options?.withMonthBeforeDay ?? true;
        const withYear = options?.withYear ?? true;
        const separator = options?.separator ?? '|';

        return `${getLocalizedDate(nativeDate, {
            withDayOfWeek,
            withMonthBeforeDay,
            withYear,
        })} ${separator} ${getLocalizedTime(nativeDate)}`;
    };

    //get time in "7/12/18 12:00" format, omit time if it's just a date
    const getLocalizedShortDateTime = (date: Date) => {
        const localizedDate = Intl.DateTimeFormat(currentLanguage.value, {
            month: '2-digit',
            year: '2-digit',
            day: '2-digit',
        }).format(date);
        const localizedTime = Intl.DateTimeFormat(currentLanguage.value, {
            hour: 'numeric',
            minute: 'numeric',
        }).format(date);

        return `${localizedDate} ${localizedTime}`.replace('00:00', '').trim();
    };

    //Format date in "07/12/23" format
    const getFormattedDate = (date?: DateInAnyForm, placeholder = '—') => {
        if (date) {
            return formatDateString(date, { locale: currentLanguage.value });
        }
        return placeholder;
    };

    //Format date in "15 February" format
    const getDayAndMonth = (date?: string | Date | null) => {
        const normalizedDate = getDate(date);
        if (normalizedDate) {
            return Intl.DateTimeFormat(currentLanguage.value, { month: 'long', day: 'numeric' }).format(normalizedDate);
        }
        return null;
    };

    /**
     * Split colon-separated time into unit-keyed number values
     * @param time {string} in format hh:mm:ss
     */
    const splitColonSeparatedTimeStringToNumbers = (
        time: string
    ): { hours: number; minutes: number; seconds: number } => {
        const [hours, minutes, seconds] = time.split(':');
        return {
            hours: Number(hours),
            minutes: Number(minutes),
            seconds: Number(seconds),
        };
    };

    const getTimeDifference = (laterDate: DateInAnyForm, earlierDate: DateInAnyForm) => {
        const nativeLaterDate = getDate(laterDate);
        const nativeEarlierDate = getDate(earlierDate);
        if (!nativeLaterDate || !nativeEarlierDate) {
            return '';
        }
        return {
            hours: differenceInHours(nativeLaterDate, nativeEarlierDate),
            minutes: differenceInMinutes(nativeLaterDate, nativeEarlierDate),
            seconds: differenceInSeconds(nativeLaterDate, nativeEarlierDate),
        };
    };

    const getApproximateIntervalLength = (time: {
        hours: number;
        minutes: number;
        seconds: number;
    }): { num: number; units: 'hours' | 'minutes' | 'seconds' } => {
        if (time.hours > 0) {
            return { units: 'hours', num: time.hours };
        } else if (time.minutes > 0) {
            return { units: 'minutes', num: time.minutes };
        } else {
            return { units: 'seconds', num: time.seconds };
        }
    };

    return {
        getApproximateIntervalLength,
        getDayAndMonth,
        getFormattedDate,
        getLocalizedDate,
        getLocalizedDateTime,
        getLocalizedShortDateTime,
        getLocalizedTime,
        getTimeDifference,
        splitColonSeparatedTimeStringToNumbers,
    };
}
