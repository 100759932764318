<template>
    <div>
        <ul class="core-menu">
            <li
                v-for="menuItem in filteredMenuItems"
                v-show="menuItem.string !== 'dashboard'"
                :key="menuItem.string"
                class="list-item"
                @mouseenter="handleItemHovered(true)"
                @mouseleave="handleItemHovered(false)"
            >
                <router-link v-bind="properties(menuItem)" class="menu-link">
                    <span>{{ menuItem.name }}</span>
                </router-link>
                <transition name="menu-item">
                    <ul v-if="menuItem.subnav && subnavExists(menuItem.subnav) && isHovered" class="core-menu-list">
                        <li v-for="(item, key) in menuItem.subnav" :key="key">
                            <DropdownItem :key="item.string" :link="item.route" :title="item.name">
                                <template #icon>
                                    <component :is="item.icon" />
                                </template>
                            </DropdownItem>
                        </li>
                    </ul>
                </transition>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, PropType, ref, watch } from 'vue';

    import { TopNavItemInterface } from '@/common/interfaces';
    import DropdownItem from '@/ui-kit/dropdown/items/DropdownItem.vue';

    type MenuItems = { [name: string]: TopNavItemInterface };

    export default defineComponent({
        name: 'CoreMenu',
        components: { DropdownItem },
        props: {
            menuItems: {
                type: Object as PropType<MenuItems>,
                required: true,
            },
        },
        setup(props) {
            const mobileItemOpen = ref<boolean[]>([]);
            const isHovered = ref(false);

            watch(
                () => props.menuItems,
                () => {
                    mobileItemOpen.value = Object.keys(props.menuItems).map(() => false);
                }
            );

            const handleItemHovered = (state: boolean) => (isHovered.value = state);

            const subnavExists = (subnav: MenuItems) => {
                return typeof subnav === 'object' && Object.keys(subnav).length > 0;
            };

            const filteredMenuItems = computed((): MenuItems => {
                if (!props.menuItems) return {};

                return Object.entries<TopNavItemInterface>(props.menuItems)
                    .filter(([_, item]) => item.access)
                    .reduce((acc, [key, value]) => {
                        acc[key] = value;
                        return acc;
                    }, {});
            });

            const properties = item => {
                const to = item.route.name ? item.route : { path: item.route.replace('/app/', '/') };
                return { to };
            };

            return {
                handleItemHovered,
                subnavExists,
                isHovered,
                filteredMenuItems,
                properties,
            };
        },
    });
</script>

<style lang="less" scoped>
    .menu-item-enter-active,
    .menu-item-leave-active {
        transition: @generic-transition;
    }

    .menu-item-enter,
    .menu-item-leave-to {
        opacity: 0;
    }

    .sub-item {
        display: flex;
        align-items: center;
        min-height: 56px;
        margin-bottom: @spacing-2;
        background-color: @bright-color-8;

        &:first-of-type {
            border-top-left-radius: @border-radius-8;
            border-top-right-radius: @border-radius-8;
        }

        &:last-of-type {
            border-bottom-right-radius: @border-radius-8;
            border-bottom-left-radius: @border-radius-8;
        }

        &.active {
            background-color: @bright-color-20;
        }
    }

    .core-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: @core-header-height;
        margin: 0;
        padding: 0;
        list-style: none;
        outline: none;

        > .list-item > a {
            color: @branding-text-color;

            &.menu-link {
                .h4-semibold();

                line-height: @core-header-height;
            }
        }

        &:hover > .list-item > a {
            color: @branding-text-color-40;
        }

        & .list-item {
            position: relative;
            display: inline-block;
            height: @core-header-height;
            padding-right: @spacing-20;
            padding-left: @spacing-20;
            color: @branding-text-color-40;
            white-space: pre;
            vertical-align: bottom;

            &:hover {
                > .core-menu-list {
                    display: block;
                }

                .menu-link {
                    color: @branding-text-color;
                    cursor: pointer;
                }
            }

            .core-menu-list {
                position: absolute;
                top: 72px;
                left: 0;
                display: none;
                flex-direction: column;
                min-width: 240px;
                padding: 0 @spacing-8 0 @spacing-8;
                padding-top: @spacing-16;
                padding-bottom: @spacing-16;
                list-style: none;
                background: @bright-color;
                border-radius: @border-radius-8;
                box-shadow: 0 2px 40px 0 @dark-color-8;
            }
        }
    }

    .mobile-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        min-height: calc(100vh - @core-header-height);
        padding: @spacing-20;
        overflow: auto;
        background-color: @branding-color;

        & ::v-deep .wrapper {
            border-bottom: 1px solid @dark-color-8;

            &:last-child {
                border-bottom: 1px solid transparent;
            }
        }

        & ::v-deep .wrapper.active {
            & .header .core-menu-list-title {
                color: @branding-text-color;
            }

            & .header i {
                color: @branding-text-color;
            }

            & .header i::before {
                margin-left: @spacing-4;
            }
        }

        ::v-deep .header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 56px;
            line-height: 56px;
            background-color: @branding-color;

            .core-menu-list-title {
                .h4();

                display: flex;
                align-items: center;
                width: 97%;
                height: 56px;
                padding-left: @spacing-16;
                color: @branding-text-color-40;
                line-height: 56px;
            }

            & i {
                margin-right: @spacing-20;
                color: @branding-text-color-40;
            }

            & i::before {
                font-size: 16px;
            }
        }

        .core-menu-list-mobile {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }
</style>
