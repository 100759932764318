export enum EntitiesList {
    PACKAGE_DOWNLOAD = 'package-download',
    PREVIEW = 'preview',
    ACKNOWLEDGE = 'acknowledge',
    ADMIN = 'admin',
    AGGREGATE = 'aggregate',
    AGGREGATED = 'aggregated',
    ATTACHMENT = 'attachment',
    ATTEMPT = 'attempt',
    BASIC_REPORTING = 'basic-reporting',
    BUILDER = 'builder',
    BULK = 'bulk',
    BULK_DELETE = 'bulk-delete',
    CANCEL = 'cancel',
    CARD = 'card',
    CATEGORY = 'category',
    CHAPTER = 'chapter',
    CHOICE_QUESTION = 'choicequestion',
    COMPLETE = 'complete',
    COURSE = 'course',
    DEACTIVATE = 'deactivate',
    DELETE_BULK = 'delete-bulk',
    DOWNLOAD = 'download',
    EMBED = 'embed',
    EVENTS = 'events',
    EVENT_LISTENER = 'eventlistener',
    FILE = 'file',
    IFRAME = 'iframe',
    IMAGE = 'image',
    IMPORT = 'import',
    INCREMENT = 'increment',
    JOB_TITLE = 'job-title',
    JOB_TITLES = 'job-titles',
    LEARNING = 'learning',
    METRICS = 'metrics',
    NOTIFICATIONS = 'notifications',
    ORGANIZATION = 'organization',
    OVERVIEW = 'overview',
    PAGE = 'page',
    CONTENT = 'content',
    COURSES = 'courses',
    PLAYER = 'player',
    STATE = 'state',
    PARSED_USERS = 'parsed-users',
    PERSONA = 'persona',
    PERSONAL = 'personal',
    PLATFORM = 'platform',
    PODCAST = 'podcast',
    PROGRESS = 'progress',
    QUESTION = 'question',
    QUEUE = 'queue',
    REPORT = 'report',
    REPORTING = 'reporting',
    RESTRICTIONS = 'restrictions',
    SCORM = 'scorm',
    SEND = 'send',
    SPECIFIC = 'specific',
    START = 'start',
    STATS = 'stats',
    STATUS = 'status',
    SUBMIT = 'submit',
    TEXT = 'text',
    TOPIC = 'topic',
    TOPICS = 'topics',
    TOPNAV = 'topnav',
    TRACK = 'track',
    UPLOAD_COMPLETED = 'upload-completed',
    UPLOAD_INITIALIZE = 'upload-initialize',
    USER = 'user',
    USERS = 'users',
    USERS_LEFT = 'users-left',
    USER_COURSE_ENGAGEMENT = 'user-course-engagement',
    USER_GROUP = 'user-group',
    USER_GROUPS = 'user-groups',
    VIDEO = 'video',
    VIEWS = 'views',
    UNASSIGN = 'unassign',
    RULE = 'rule',
    ASSIGN = 'assign',
    SEGMENT = 'segment',
    GROUP = 'group',
    GROUPS = 'groups',
    USERS_NUMBER = 'users-number',
}
