<template>
    <CoreToolTip placement="bottom" class="tool-tip" :interactive="false" :text="tooltip">
        <template #trigger>
            <button
                class="core-table-v2-icon-button"
                :disabled="isLoading || disabled"
                :class="buttonClass"
                v-on="$listeners"
            >
                <template v-if="!isLoading">
                    <template v-if="$slots.icon">
                        <div :class="iconClass" class="info-color display-flex">
                            <slot name="icon" />
                        </div>
                    </template>
                </template>
                <CircleSpinner v-else size="small" />
            </button>
        </template>

        <template v-if="tooltip" #content>
            <div class="core-table-v2-icon-button__tooltip">
                {{ tooltip }}
            </div>
        </template>
    </CoreToolTip>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';

    import CircleSpinner from '@/common/components/spinner/CircleSpinner.vue';
    import CoreToolTip from '@/common/components/CoreToolTip.vue';

    @Component({ components: { CircleSpinner, CoreToolTip } })
    export default class CoreTableIconButton extends Vue {
        @Prop({ default: false }) public isLoading!: boolean;
        @Prop({ default: false }) public tooltip!: string;
        @Prop({ type: Boolean, default: false }) public disabled!: boolean;
        @Prop({ default: 'primary' }) public hoverState!: string;
        @Prop({ default: false }) public isActive!: boolean;

        public get buttonClass() {
            return {
                active: this.isActive,
                disabled: this.disabled,
                [this.hoverState]: true,
            };
        }

        public get iconClass() {
            return {
                'core-table-v2-icon-button__icon': true,
                disabled: this.disabled,
            };
        }
    }
</script>

<style lang="less" scoped>
    .core-table-v2-icon-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        margin: 0;
        background: none;
        border: none;
        border-radius: @border-radius-8;
        outline: none;
        cursor: pointer;

        .generic-transition(background-color);

        .sm-viewport-and-smaller({
            width: 40px;
            height: 40px;
            padding: @spacing-12;
        });

        &:not(.disabled).active,
        &:not(.disabled):hover {
            background-color: @bright-color;

            &.primary > * {
                color: @primary-color;
            }

            &.danger > * {
                color: @danger-color;
            }
            .sm-viewport-and-smaller({
                &:not(.active).primary > * {
                    color: @info-color;
                }
            });
        }

        &.disabled {
            cursor: default;
        }

        .disabled {
            cursor: default;
            opacity: 0.4;
        }

        .sm-viewport-and-smaller({
            background-color: var(--color-info-8);

            &:not(.disabled):not(.active):hover {
                background-color: var(--color-info-8);
            }

            &:not(.disabled).active {
                background-color: var(--color-primary-8);

                .core-table-v-8-icon-button__icon.clickable {
                    color: var(--color-primary);
                }
            }

            .core-table-v-8-icon-button__icon.clickable:hover {
                color: var(--color-info);
            }
        });
    }
</style>
