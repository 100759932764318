<template>
    <portal to="modal-host">
        <div
            v-show="isModalVisuallyVisible"
            ref="refModal"
            :key="modalKey"
            class="modal-window-wrapper"
            :class="modalClasses"
            @click.self="backdropClickHandler"
        >
            <div
                class="modal-window-container"
                :style="modalWindowContainerStyles"
                :data-testid="modalTestId"
                :class="[additionalClassContainerWrapper]"
                @click.self="backdropClickHandler"
            >
                <ModalWindowDialog
                    v-if="isModalVisuallyVisible"
                    role="dialog"
                    class="modal-window"
                    :style="modalStyles"
                    :class="modalClass"
                    @animationEnd="handleAnimationEnd"
                >
                    <div v-if="showHeader" class="header">
                        <slot name="header">
                            <h2
                                v-tippy="showTooltipHeader ? { content: headerText } : null"
                                class="header-title-text"
                                :class="{ 'with-help': showTooltipHeader }"
                            >
                                {{ headerText }}
                            </h2>
                        </slot>
                    </div>

                    <div v-if="showCloseButton" class="close-icon" @click="requestClose('Close button click')">
                        <elm-actions-close-regular-icon
                            v-tippy="{ content: closeButtonTooltip, onShow: () => !!closeButtonTooltip }"
                            size="24"
                        />
                    </div>

                    <div
                        class="content"
                        :style="contentStyles"
                        :class="{
                            scrollable: isScrollable,
                            'no-header': !showHeader || (!$slots['header'] && !headerText),
                            'with-background': withBackground,
                            'no-footer': !$slots['footer'],
                        }"
                        @click="$emit('contentClick')"
                    >
                        <slot name="content"></slot>
                    </div>

                    <div v-show="$slots['footer']" class="footer">
                        <slot name="footer"></slot>
                    </div>
                </ModalWindowDialog>
            </div>
        </div>
    </portal>
</template>

<script lang="ts">
    import '@eloomi/icons/actions/actions-close-regular';

    import { useEventListener } from '@vueuse/core';
    import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
    import { isNumber } from 'lodash';
    import { nanoid } from 'nanoid';
    import { computed, defineComponent, nextTick, onMounted, onUnmounted, PropType, ref, watch } from 'vue';

    import { getViewportMouseLocation, makeLogger, randomId } from '@/common/services';
    import { backButtonService } from '@/common/services/mobile-fallback/native-back-button-service';
    import { useModalStack } from '@/ui-kit/modal-window/modal-stack.composable';
    import ModalWindowDialog from '@/ui-kit/modal-window/ModalWindowDialog.vue';

    const HEADER_HEIGHT = 80;
    const FOOTER_HEIGHT = 97;

    type ModalStatus = 'opening' | 'open' | 'closing' | 'closed';

    const log = makeLogger('modal-window');

    const modalWindow = defineComponent({
        components: {
            ModalWindowDialog,
        },
        props: {
            headerText: {
                type: String as PropType<string | null>,
                default: null,
            },
            isVisible: Boolean as PropType<boolean>,
            isScrollable: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            showCloseButton: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            showTooltipHeader: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            showHeader: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            withBackground: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            hideOnBackdropClick: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            closeButtonTooltip: {
                type: String as PropType<string | null>,
                default: null,
            },
            width: {
                type: [Number, String, null] as PropType<number | string | null>,
                default: 621,
            },
            height: {
                type: Number as PropType<number | null>,
                default: null,
            },
            appearFrom: {
                type: String as PropType<'mouse-position' | 'screen-center' | 'slide-up'>,
                default: 'mouse-position',
            },
            modalWindowClass: {
                type: String,
                default: null,
            },
            modalWindowWrapperClass: {
                type: String,
                default: null,
            },
            modalTestId: String as PropType<string | undefined>,
            verticalAlignment: {
                type: String as PropType<'top' | 'center' | 'bottom ' | 'stretch'>,
                default: null,
            },
            additionalClassContainerWrapper: {
                type: String,
                default: '',
            },
            withFooterSeparator: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            withBlurryBackdrop: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        emits: ['request-close', 'close', 'keyup', 'contentClick'],
        setup(props, { emit, slots }) {
            const modalStatus = ref<ModalStatus>('closed');
            const modalId = randomId();
            const shortId = modalId.slice(0, 6);
            const { addLastOpenedModal, lastOpenedModal, removeIdFromList, hasOpenedModals } = useModalStack();
            const refModal = ref<HTMLDivElement | null>(null);
            const modalKey = nanoid();

            const setStatus = (status: ModalStatus) => {
                log(`Modal [id: ${shortId}] state: ${status}`);
                modalStatus.value = status;
            };

            const modalClass = computed<Array<string | Record<string, boolean>>>(() => {
                return [
                    props.modalWindowClass,
                    { closing: modalStatus.value === 'closing' },
                    `${props.appearFrom}-animation`,
                ];
            });

            const isModalVisuallyVisible = computed(() => {
                const visibleStatues: ModalStatus[] = ['opening', 'open', 'closing'];
                return visibleStatues.includes(modalStatus.value);
            });

            onMounted(() => {
                log(`Modal [id: ${shortId}] is mounted. Ref:`, refModal.value);
            });

            onUnmounted(() => {
                if (isModalVisuallyVisible.value) {
                    closeModal();
                }

                log(`Modal [id: ${shortId}] is unmounted`);
            });

            let nativeBackButtonActionCleanup: (() => void) | null = null;

            function cleanupNativeBackButtonAction() {
                if (!nativeBackButtonActionCleanup) return;

                nativeBackButtonActionCleanup();
                nativeBackButtonActionCleanup = null;
            }

            function addNativeBackButtonAction() {
                cleanupNativeBackButtonAction();

                nativeBackButtonActionCleanup = backButtonService.pushAction(() => {
                    requestClose('Back button press');
                    nativeBackButtonActionCleanup = null;
                });
            }

            useEventListener(document, 'keyup', (event: KeyboardEvent) => {
                if (props.isVisible && modalId === lastOpenedModal.value) {
                    emit('keyup', event);
                    event.stopImmediatePropagation();

                    if (event.key === 'Escape' && (props.hideOnBackdropClick || props.showCloseButton)) {
                        requestClose('Escape key press');
                    }
                }
            });

            const modalWindowContainerStyles = computed(() => {
                let styles = {};

                if (!isNumber(props.width) && (props.width === null || (props.width as string).includes('%'))) {
                    styles = {
                        display: 'flex',
                        justifyContent: 'center',
                    };
                }

                return styles;
            });

            const modalStyles = computed(() => {
                return {
                    width: isNumber(props.width) ? `${props.width}px` : props.width,
                };
            });

            const modalClasses = computed(() => {
                return {
                    scrollable: props.isScrollable || undefined,
                    'with-blurry-backdrop': props.withBlurryBackdrop || undefined,
                    'with-footer-separator': props.withFooterSeparator,
                    [`vertical-alignment-${props.verticalAlignment}`]: props.verticalAlignment,
                    [props.modalWindowWrapperClass]: props.modalWindowWrapperClass,
                };
            });

            const contentStyles = computed(() => {
                let height: string | undefined;

                if (props.height !== null && Number.isFinite(props.height)) {
                    const footerHeight = slots.footer ? FOOTER_HEIGHT : 0;
                    height = `${props.height - HEADER_HEIGHT - footerHeight}px`;
                }

                if (!props.isScrollable) {
                    height = 'auto';
                }

                return {
                    height,
                };
            });

            const getClickOrigin = () => {
                switch (props.appearFrom) {
                    case 'mouse-position': {
                        const screenWidthHalf = window.innerWidth / 2;
                        const screenHeightHalf = window.innerHeight / 2;
                        const mouseLocation = getViewportMouseLocation();

                        return {
                            x: screenWidthHalf - mouseLocation.x + 'px',
                            y: screenHeightHalf - mouseLocation.y + 'px',
                        };
                    }
                    default: {
                        return {
                            x: null,
                            y: null,
                        };
                    }
                }
            };

            const closeModal = () => {
                setStatus('closed');

                log(`Modal [id: ${shortId}] enables body scroll. Ref:`, refModal.value);
                enableBodyScroll(refModal.value!);
                removeIdFromList(modalId);

                if (!hasOpenedModals.value) {
                    log(`Modal [id: ${shortId}] clear all body scroll locks.`);
                    clearAllBodyScrollLocks();
                }

                if (refModal.value) {
                    refModal.value.style.removeProperty('--click-x');
                    refModal.value.style.removeProperty('--click-y');
                }

                emit('close');
            };

            const requestClose = (reason?: string) => {
                log(`Modal [id: ${shortId}] requests close. Reason: ${reason}`);
                emit('request-close');
            };

            const backdropClickHandler = () => {
                if (props.hideOnBackdropClick) {
                    requestClose('Backdrop click');
                }
            };

            const setMouseClickOrigin = () => {
                const clickOrigin = getClickOrigin();

                nextTick(() => {
                    if (refModal.value && props.isVisible) {
                        refModal.value.style.setProperty('--click-x', clickOrigin.x);
                        refModal.value.style.setProperty('--click-y', clickOrigin.y);
                    }
                });
            };

            const startOpeningModal = () => {
                setStatus('opening');
                addLastOpenedModal(modalId);
                setMouseClickOrigin();

                nextTick(() => {
                    log(`Modal [id: ${shortId}] disables body scroll. Ref:`, refModal.value);
                    disableBodyScroll(refModal.value!);
                });
            };

            watch(
                () => props.isVisible,
                () => {
                    cleanupNativeBackButtonAction();

                    if (props.isVisible) {
                        startOpeningModal();
                        addNativeBackButtonAction();
                    } else {
                        setStatus('closing');
                    }
                }
            );

            if (props.isVisible) {
                startOpeningModal();
            }

            function handleAnimationEnd() {
                if (modalStatus.value === 'closing') {
                    closeModal();
                }

                if (modalStatus.value === 'opening') {
                    setStatus('open');
                }
            }

            return {
                backdropClickHandler,
                contentStyles,
                handleAnimationEnd,
                isModalVisuallyVisible,
                modalClass,
                modalClasses,
                modalKey,
                modalStyles,
                modalWindowContainerStyles,
                refModal,
                requestClose,
            };
        },
    });

    export default modalWindow;
</script>

<style lang="less" scoped>
    @modal-min-height: 260px;
    @header-height: 80px;
    @footer-height: 97px;
    @spacing-from-vertical-side: @spacing-40;
    @spacing-from-horizontal-side: @spacing-40;
    @desktop-border-radius: @border-radius-24;

    .start-appear {
        top: calc(var(--click-y) * -1);
        left: calc(var(--click-x) * -1);
        transform: scale(0);
        opacity: 0;
    }

    .end-appear {
        top: 0;
        left: 0;
        transform: scale(1);
        opacity: 1;
    }

    @keyframes appear {
        0% {
            .start-appear();
        }

        100% {
            .end-appear();
        }
    }

    @keyframes disappear {
        0% {
            .end-appear();
        }

        100% {
            .start-appear();
        }
    }

    .start-slide-up {
        transform: translateY(100vh);
        opacity: 0;
    }

    .end-slide-up {
        transform: translateY(0);
        opacity: 1;
    }

    @keyframes slide-up {
        0% {
            .start-slide-up();
        }

        100% {
            .end-slide-up();
        }
    }

    @keyframes slide-down {
        0% {
            .end-slide-up();
        }

        100% {
            .start-slide-up();
        }
    }

    .animation-options {
        animation-duration: @generic-duration;
        animation-timing-function: @generic-easing;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
    }

    .modal-window-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        z-index: @modal-overlay-z-index;
        display: flex;
        width: 100vw;
        height: 100%;
        background-color: @dark-color-80;

        &.with-blurry-backdrop {
            background-blend-mode: soft-light, normal;
            backdrop-filter: blur(10px);
        }

        &:not(.scrollable) {
            overflow-y: overlay;
            .styled-scrollbar(4px, 4px);
        }

        &.scrollable .modal-window {
            max-height: calc(100% - @spacing-from-vertical-side * 2);
        }

        &.vertical-alignment-top .modal-window-container,
        &.vertical-alignment-bottom .modal-window-container {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 100%;
            margin: 0;
        }

        &.vertical-alignment-stretch .modal-window-container {
            height: 100%;

            .content {
                height: 100%;
            }
        }

        &.vertical-alignment-top .modal-window-container {
            align-items: flex-start;

            .modal-window {
                margin-top: 0;
            }
        }

        &.vertical-alignment-bottom .modal-window-container {
            align-items: flex-end;

            .modal-window {
                margin-bottom: 0;
            }
        }

        &.vertical-alignment-bottom.scrollable .modal-window-container {
            .modal-window {
                max-height: calc(100% - @spacing-from-vertical-side);
            }

            .content {
                max-height: calc(100vh - @spacing-from-vertical-side - @footer-height);
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;

                &.no-footer {
                    max-height: calc(100vh - @spacing-from-vertical-side - @header-height);
                }
            }
        }

        &.with-footer-separator .footer {
            border-top: 1px solid @info-color-8;
        }
    }

    .modal-window-container {
        margin: auto;
    }

    .modal-window {
        .animation-options();

        position: relative;
        z-index: @modal-dialog-z-index;
        margin: @spacing-from-vertical-side @spacing-from-horizontal-side;
        background-color: @bright-color;
        border-radius: @desktop-border-radius;
        animation-name: appear;

        &.slide-up-animation {
            animation-name: slide-up;
        }

        &.closing {
            .animation-options();

            animation-name: disappear;

            &.slide-up-animation {
                animation-name: slide-down;
            }
        }

        .sm-viewport-and-smaller({
            max-width: 100%;
            margin-top: @spacing-24 !important;
            margin: 0 @spacing-8;
            border-radius: @border-radius-16;

            max-width: calc(100vw - 32px) !important;
        });
    }

    .header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: @header-height;
        padding: 0 @spacing-24;
        background-color: @bright-color;
        border-bottom: 1px solid @info-color-8;
        border-top-left-radius: @desktop-border-radius;
        border-top-right-radius: @desktop-border-radius;

        .sm-viewport-and-smaller({
            border-top-left-radius: @border-radius-16;
            border-top-right-radius: @border-radius-16;
        });
    }

    .content {
        .generic-transition(~'background-color');

        min-height: calc(@modal-min-height - @header-height - @footer-height);
        overflow-y: auto;
        background-color: @bright-color;

        &.with-background {
            background-color: @branding-color-4;
        }

        .styled-scrollbar;

        &.scrollable {
            max-height: calc(
                100vh - @spacing-from-vertical-side - @spacing-from-vertical-side - @header-height - @footer-height
            );
        }

        &.no-header {
            border-top-left-radius: @desktop-border-radius;
            border-top-right-radius: @desktop-border-radius;
        }

        &.no-footer {
            border-bottom-right-radius: @desktop-border-radius;
            border-bottom-left-radius: @desktop-border-radius;
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        background-color: @bright-color;
        border-radius: 0 0 @desktop-border-radius @desktop-border-radius;

        .sm-viewport-and-smaller({
            border-bottom-left-radius: @border-radius-16;
            border-bottom-right-radius: @border-radius-16;
        });
    }

    .close-icon {
        position: absolute;
        top: @spacing-20;
        right: -@spacing-40;
        width: auto;
        height: fit-content;
        color: @bright-color;
        cursor: pointer;

        &:hover {
            color: @primary-color;
        }

        svg {
            outline: none;
            cursor: pointer;
        }

        .sm-viewport-and-smaller({
            top: -@spacing-40;
            right: @spacing-8;
        });
    }

    .header-title-text {
        .text-overflow();

        &.with-help:hover {
            cursor: help;
        }
    }
</style>
