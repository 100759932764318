import { computed, ref } from 'vue';

import { useTranslation } from '@/common/composables';
import { datesAreEqual, formatDate, getDayEnd, getDayStart, isMultipleDays } from '@/common/services';
import { ElementType } from '@/common/types';

export type Interval = { start: Date | null; end: Date | null };
export const predefinedRanges = ['future', 'past', 'today', 'tomorrow', 'week', 'month', 'quarter', 'year'] as const;
export type PredefinedRange = ElementType<typeof predefinedRanges>;
export type PickerMode = 'predefined' | 'calendar';

const dayMilliseconds = 1000 * 60 * 60 * 24;

export function useEventOverviewDateSelectorComposable() {
    const { trans } = useTranslation();

    const mode = ref<PickerMode>('predefined');
    const currentDateFrom = ref<Date | null>();
    const currentDateTo = ref<Date | null>();

    const predefinedIntervals = computed(() => {
        const today = getDayStart();
        const todayEnd = getDayEnd();

        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDate();

        const tomorrowEnd = getDayEnd(new Date(year, month, day + 1));
        const tomorrow = getDayStart(new Date(year, month, day + 1));
        const yesterday = getDayEnd(new Date(year, month, day - 1));
        const weekStart = new Date(year, month, day - today.getDay());
        const weekEnd = new Date(weekStart.getTime() + dayMilliseconds * 7 - 1);
        const monthStart = new Date(year, month);
        const monthEnd = new Date(year, month + 1, 1, 0, 0, 0, -1);
        const quarterStart = new Date(year, month - (month % 3));
        const quarterEnd = new Date(year, quarterStart.getMonth() + 3, 1, 0, 0, 0, -1);
        const yearStart = new Date(year, 0);
        const yearEnd = new Date(year + 1, 0, 1, 0, 0, 0, -1);
        return {
            future: { start: today, end: null },
            past: { start: null, end: yesterday },
            tomorrow: { start: tomorrow, end: tomorrowEnd },
            today: { start: today, end: todayEnd },
            week: { start: weekStart, end: weekEnd },
            month: { start: monthStart, end: monthEnd },
            quarter: { start: quarterStart, end: quarterEnd },
            year: { start: yearStart, end: yearEnd },
        } as Record<PredefinedRange, Interval>;
    });
    const isSelected = (range: PredefinedRange) => {
        const { start, end } = predefinedIntervals.value[range];
        return datesAreEqual(currentDateFrom.value, start) && datesAreEqual(currentDateTo.value, end);
    };
    const selectedPredefinedRange = computed(() => {
        return predefinedRanges.find(range => isSelected(range));
    });
    const isCustomDatesSelected = computed(() => {
        return Boolean(!selectedPredefinedRange.value && currentDateFrom.value && currentDateTo.value);
    });
    const getPredefinedLabel = (range: PredefinedRange) => {
        return trans(`spa.events-overview.filters.date.predefined.${range}`);
    };
    const selectedText = computed(() => {
        if (selectedPredefinedRange.value) {
            return getPredefinedLabel(selectedPredefinedRange.value);
        } else if (isCustomDatesSelected.value) {
            if (isMultipleDays(currentDateFrom.value!, currentDateTo.value!)) {
                return `${formatDate(currentDateFrom.value!)} - ${formatDate(currentDateTo.value!)}`;
            } else {
                return formatDate(currentDateFrom.value!);
            }
        } else {
            return '';
        }
    });

    return {
        currentDateFrom,
        currentDateTo,
        getPredefinedLabel,
        isCustomDatesSelected,
        isSelected,
        mode,
        predefinedIntervals,
        predefinedRanges,
        selectedPredefinedRange,
        selectedText,
    };
}
