<template>
    <Popover
        ref="popover"
        :isTargetInline="false"
        autoMaxHeight
        closeOnContentClick
        class="time-selector"
        :class="{ invalid: isInvalid, disabled: isDisabled }"
    >
        <template #target>
            <TextField
                ref="textField"
                :value="timeFromDateValue"
                :debounce="300"
                :isInvalid="isInvalid"
                :isDisabled="isDisabled"
                :placeholder="placeholder"
                testId="time-selector"
                size="small"
                @keydown="handleHotkey"
                @change="validateAndUpdateValue"
                @focus="showDropdown"
            >
                <template #left>
                    <elm-date-time-time-clock-icon size="18" class="time-selector-icon" />
                </template>
            </TextField>
        </template>

        <template #content>
            <BasicDropdownContainer ref="container" class="options" @mounted="handleDropdownMount">
                <DropdownItem
                    v-for="(option, index) in options"
                    :key="option.getTime()"
                    class="option"
                    :data-option-index="index"
                    :title="dateToTime(option)"
                    :isSelected="isOptionHighlighted(index)"
                    @click="emitValue(option)"
                >
                    <template #content>
                        <div class="option-content">
                            {{ dateToTime(option) }}
                            <span v-if="Boolean(from) && getDuration(option)" class="duration">
                                ({{ getDuration(option) }})
                            </span>
                        </div>
                    </template>
                </DropdownItem>
            </BasicDropdownContainer>
        </template>
    </Popover>
</template>

<script lang="ts">
    import '@eloomi/icons/date-time/date-time-time-clock';

    import { defineComponent, PropType, ref } from 'vue';

    import { useTranslation } from '@/common/composables';
    import { isBrowserLocale24h } from '@/common/services';
    import { getDayStart } from '@/common/services/date-fns.utils';
    import HighlightedOptionScroller from '@/common/services/options-scroller';
    import { formatCourseDuration } from '@/courses/utils';
    import BasicDropdownContainer from '@/ui-kit/dropdown/containers/BasicDropdownContainer.vue';
    import DropdownItem from '@/ui-kit/dropdown/items/DropdownItem.vue';
    import Popover from '@/ui-kit/popover/Popover.vue';
    import TextField from '@/ui-kit/text-field/TextField.vue';

    const dayMilliseconds = 24 * 60 * 60 * 1000;

    export default defineComponent({
        name: 'TimeSelector',
        components: {
            BasicDropdownContainer,
            DropdownItem,
            Popover,
            TextField,
        },
        props: {
            stepMinutes: { type: Number, default: 30 },
            selectedValue: { type: Date as PropType<Date | null>, default: null },
            from: { type: Date, default: null },
            to: { type: Date, default: null },
            isInvalid: { type: Boolean, default: false },
            isDisabled: { type: Boolean, default: false },
            placeholder: { type: String, default: 'Select time' },
        },
        setup() {
            const popover = ref<InstanceType<typeof Popover>>();
            const textField = ref<InstanceType<typeof TextField>>();
            const container = ref<InstanceType<typeof BasicDropdownContainer>>();
            return { container, popover, textField, ...useTranslation() };
        },

        data: () => ({
            highlightedIndex: null as number | null,
            startedToSelectValueUsingKeyboard: false,
            optionScroller: new HighlightedOptionScroller(),
        }),

        computed: {
            timeFromDateValue() {
                return this.dateToTime(this.selectedValue);
            },

            options() {
                const res: Date[] = [];
                let current = this.startOfTheDay.getTime();
                const from = this.from ? this.from.getTime() : Number.MIN_VALUE;
                const to = Math.min(this.endOfTheDay.getTime(), this.to ? this.to.getTime() : Number.MAX_VALUE);
                while (current < to) {
                    if (current > from) {
                        res.push(new Date(current));
                    }
                    current += this.stepMinutes * 60 * 1000;
                }
                return res;
            },

            startOfTheDay() {
                return getDayStart(this.selectedValue);
            },

            endOfTheDay() {
                return new Date(this.startOfTheDay.getTime() + dayMilliseconds - 1);
            },
        },

        methods: {
            emitValue(date: Date | null) {
                this.$emit('update:selected', date);
                return date;
            },

            showDropdown() {
                this.startedToSelectValueUsingKeyboard = false;
                this.highlightedIndex = this.options.findIndex(
                    option => this.dateToTime(option) === this.dateToTime(this.selectedValue)
                );

                this.popover?.show();
            },

            validateAndUpdateValue(value) {
                if (this.isValidTimeInput(value)) {
                    if (!value) {
                        this.emitValue(null);
                        return;
                    }
                    const newDate = this.selectedValue ? new Date(this.selectedValue) : new Date();
                    const [time, meridiem] = value.split(' ');
                    const [hours, minutes] = time.split(':');

                    newDate.setHours(meridiem && meridiem.toLowerCase() === 'pm' ? Number.parseInt(hours) + 12 : hours);
                    newDate.setMinutes(minutes);

                    if (!Number.isNaN(newDate.getTime())) {
                        this.emitValue(newDate);
                    }
                }
            },

            getDuration(option: Date) {
                if (!this.from) return null;

                const durationInSeconds = Math.floor((option.getTime() - this.from.getTime()) / 1000);
                return formatCourseDuration(durationInSeconds, this.trans);
            },

            handleHotkey(event: KeyboardEvent) {
                switch (event.key) {
                    case 'Down':
                    case 'ArrowDown': {
                        this.highlightNext();
                        break;
                    }
                    case 'Up':
                    case 'ArrowUp': {
                        this.highlightPrevious();
                        break;
                    }
                    case 'Enter': {
                        this.onEnterPressed(event);
                        break;
                    }
                    case 'Esc':
                    case 'Escape': {
                        this.cancelInput();
                        break;
                    }
                }
            },

            highlightNext() {
                this.startedToSelectValueUsingKeyboard = true;
                let newIndex: number | null = this.highlightedIndex === null ? 0 : this.highlightedIndex + 1;

                if (newIndex >= this.options.length) {
                    newIndex = null;
                }

                this.highlightedIndex = newIndex;
                this.optionScroller.scrollToOption(this.highlightedIndex);
            },

            highlightPrevious() {
                this.startedToSelectValueUsingKeyboard = true;
                let newIndex: number | null =
                    this.highlightedIndex === null ? this.options.length - 1 : this.highlightedIndex - 1;

                if (newIndex < 0) {
                    newIndex = null;
                }

                this.highlightedIndex = newIndex;
                this.optionScroller.scrollToOption(this.highlightedIndex);
            },

            cancelInput() {
                this.popover?.hide();
                this.textField?.input?.blur();
            },

            onEnterPressed(event: KeyboardEvent) {
                if (this.highlightedIndex !== null) {
                    if (this.highlightedIndex !== -1 && this.startedToSelectValueUsingKeyboard) {
                        this.emitValue(this.options[this.highlightedIndex]);
                    } else {
                        this.validateAndUpdateValue((event.target as HTMLInputElement).value);
                    }

                    this.cancelInput();
                }
            },

            isOptionHighlighted(index) {
                return this.highlightedIndex === index;
            },

            isOptionSelected(option: Date) {
                return this.dateToTime(option) === this.timeFromDateValue;
            },

            handleDropdownMount() {
                this.optionScroller.setContainer(this.container!).scrollToOption(this.highlightedIndex);
            },

            dateToTime(date?: Date | null) {
                if (!date) {
                    return '';
                }
                return date.toLocaleTimeString(this.currentLanguage || navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: !isBrowserLocale24h(this.currentLanguage),
                });
            },

            isValidTimeInput(value: string) {
                if (!value) {
                    return true;
                }

                const [time, meridiem] = value.split(' ');
                const [hours, minutes] = time.split(':');

                if (
                    meridiem &&
                    (!/^[ap]m$/i.test(meridiem) || Number.parseInt(hours) > 12 || Number.parseInt(hours) < 1)
                ) {
                    return;
                }

                return (
                    Number.isFinite(Number.parseInt(hours)) &&
                    Number.parseInt(hours) < 24 &&
                    Number.parseInt(hours) >= 0 &&
                    Number.isFinite(Number.parseInt(minutes)) &&
                    Number.parseInt(minutes) < 60 &&
                    Number.parseInt(minutes) >= 0
                );
            },
        },
    });
</script>

<style lang="less" scoped>
    .options {
        max-height: 300px;

        .option .option-content {
            font-weight: 400;
        }

        .option .duration {
            color: @info-color;
        }

        .option:hover .duration {
            color: @primary-color-60;
        }
    }

    .time-selector {
        .time-selector-icon {
            margin-right: @spacing-16;
            color: @info-color;
        }

        &.invalid {
            .time-selector-icon {
                color: @danger-color;
            }
        }

        &.disabled {
            .time-selector-icon {
                color: @info-color-40;
            }
        }
    }
</style>
